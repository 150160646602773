<template>
  <b-col md="4" style="margin: 50px auto">
    <b-card>
      <div slot="header" class="text-center">{{ $t("resetPassword") }}</div>
      <b-form v-on:submit.prevent="changePassword" novalidate>
        <b-form-group
          id="passInputGroup5"
          :label="$t('newPassword')"
          label-for="newPassword"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-lock"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              id="newPassword"
              type="password"
              aria-describedby="input1LiveFeedback5"
              :placeholder="$t('newPassword')"
              autocomplete="new-password"
              v-model="newPassword"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          id="passInputGroup5"
          :label="$t('passwordConfirm')"
          label-for="confirmPassword"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-lock"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              id="confirmPassword"
              type="password"
              aria-describedby="input1LiveFeedback5"
              :placeholder="$t('passwordConfirm')"
              autocomplete="new-password"
              v-model="confirmPassword"
            />
          </b-input-group>
        </b-form-group>

        <div class="form-group form-actions">
          <b-button type="submit" size="sm" variant="success">{{
            $t("save")
          }}</b-button>
        </div>
      </b-form>
    </b-card>
  </b-col>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";

export default {
  name: "ResetPassword",

  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      notification: {
        message: "",
        type: "",
      },
    };
  },
  computed: {},
  methods: {
    changePassword() {
      if (this.newPassword.length < 5 || this.confirmPassword.length < 5) {
        toast.warning(this.$t("emptyPassword"));
        return;
      }

      if (this.confirmPassword != this.newPassword) {
        toast.warning(this.$t("passwordsDoesNotMatch"));
        return;
      }
      let path = "auth/resetpassword";
      let data = {
        id: parseInt(this.$route.query.id),
        token: this.$route.query.token,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };
      http.post(path, data).then((data) => {
        if (data) {
          toast.success();
          this.$router.push({ path: "/login" });
        }
      });
      return false;
    },
  },
};
</script>